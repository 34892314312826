import React from 'react';
import hero_section from "../assets/hero_section_img.webp";
import about_us_img from "../assets/about_us.webp";
import mission_img from "../assets/vision_mg.webp";
import outpat from "../assets/outpat.webp";
import inpat from "../assets/inpat.webp";
import training_img from "../assets/training.webp";
import workshop_img from "../assets/workshop.webp";
import crm_img from "../assets/crm_img.webp";
import logo from "../assets/claim-guad-logo.jpeg"
import { Link } from 'react-router-dom';
import ContactUs from '../components/ContactUs'

const Home = () => {
  const date = new Date();
  return (
    <div className='flex flex-col font-sans'>

      <nav className="bg-[#0C217A] p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center text-white text-lg font-bold">
          <Link to='/home'>Claim Guard KSA</Link>
          <div className="flex items-center gap-4">

            <Link to={`/home`}>
              <img src={logo} alt='logo' width={100} className='rounded-full' />
            </Link>

          </div>

          <div className="flex items-center gap-6">
            <Link to="/login" className="text-white hover:underline transition duration-200">
              Login
            </Link>
          </div>
        </div>
      </nav>
      <img src={hero_section} alt='hero_section_img' />
      <div className='flex flex-col bg-white w-full py-5 px-16 gap-5'>
        <span className='text-center text-4xl font-bold py-5'>About Claim Guard KSA</span>
        <div className='grid grid-cols-2 md:grid-cols-2 gap-5'>

          <div className='flex flex-row justify-center items-center py-5 px-10 '><img src={about_us_img} width={800} height={800} alt="About us" className='roounded-sm' /></div>
          <div className='flex flex-col gap-3 items-center'>
            <span className='text-center text-3xl font-bold'>About</span>
            <span className='text-center py-10 px-14  text-xl font-semibold text-[#5E5E5E]'>
              Claim Guard KSA,  Is the first medical auditor and reviewer online service in the EMEA using the same methods and steps of the insurance companies. CGK is using a cutting edge AI technology for Outpatient claims review, designed based on the same technology used by the major insurance companies in Saudi Arabia , Our highly experienced team of insurance doctors are responsible for your Inpatient claims and also offering training courses and workshops to help your doctors and employees know all the regulations and steps to make generate complete valid claims.
            </span>
          </div>

          <div className='flex flex-col gap-3 items-center mt-10'>
            <span className='text-center text-3xl font-bold'>Our Mission</span>
            <span className='text-center py-10 px-14  text-xl font-semibold text-[#5E5E5E]'>
              At Claim Guard KSA, our objective is to help Healthcare providers by reviewing and auditing the medical claims by cutting edge AI tool for the outpatient claims and a team of highly experienced doctors for inpatient claims, to Avoid Insurance Rejections and Increase the Revenues, allowing them to focus on serving and treating patients. we also offer training courses and workshops (onsite/online) to healthcare providers to avoid future Rejections,  and full RCM services
            </span>
          </div>
          <div className='flex flex-row justify-center items-center py-5 px-10 '><img src={mission_img} alt="mission" className='roounded-sm' width={600} height={600} /></div>

        </div>
        <div className='w-full py-5 bg-gray-100 text-center'>
          <a href='https://claimguardksa.com/signup' target='_blank' className='text-center'><button className='p-3 bg-blue-600 text-white rounded-full '>Register Now</button></a>
        </div>
        <div className='flex flex-col gap-5'>
          <div className='text-center text-4xl font-semibold text-[#5E5E5E]'>
            Our services
          </div>
          <div className='text-center text-2xl font-semibold text-[#5E5E5E]'>
            Claims review & audit
          </div>
          <div className='flex flex-row gap-16 justify-center'>
            <div className='flex flex-col  gap-1'>
              <img src={outpat} alt='outpatient' width={500} height={500} />
              <div className='flex flex-row justify-between py-5'> <span className='font-bold  text-2xl'>Outpatient </span><span>1 - 1.5 SR/ claim</span></div>
            </div>

            <div className='flex flex-col  gap-1'>
              <img src={inpat} alt='inpatient' width={500} height={500} />
              <div className='flex flex-row justify-between py-5'> <span className='font-bold  text-2xl'>Inpatient</span><span>15-20 SR/claim</span></div>
              <span>Review takes 24-48 hours</span>
            </div>
          </div>

          <div className='text-center mt-5 mb-5 text-2xl font-semibold text-[#5E5E5E]'>
            Training and workshops
          </div>
          <div className='flex flex-row gap-16 justify-center'>
            <div className='flex flex-col  gap-1'>
              <img src={training_img} alt='outpatient' width={500} height={500} />
              <div className='flex flex-row justify-between py-5'> <span className='font-bold  text-2xl'>Online training </span><span>Contact US</span></div>
            </div>

            <div className='flex flex-col  gap-1'>
              <img src={workshop_img} alt='inpatient' width={500} height={500} />
              <div className='flex flex-row justify-between py-5'> <span className='font-bold  text-2xl'>Onsite workshop</span><span>Contact Us</span></div>

            </div>
          </div>

          <div className='text-center mt-5 mb-5 text-2xl font-semibold text-[#5E5E5E]'>
            Full CRM Services
          </div>
          <div className='flex flex-row gap-16 justify-center'>
            <div className='flex flex-col  gap-1'>
              <img src={crm_img} alt='outpatient' width={500} height={500} />
              <div className='flex flex-row justify-between py-5'> <span className='font-bold  text-2xl'>Full CRM </span><span>Contact US</span></div>
            </div>
          </div>
        </div>
        <div>
          <ContactUs/>
        </div>
      </div>
      <div className='bg-blue-800 text-white  py-8 text-normal font-thin text-center  '>
        Copyright © {date.getFullYear()} Claim Guard KSA - All Rights Reserved.
      </div>
    </div>
  )
}

export default Home