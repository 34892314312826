// src/slices/fileUploadSlice.js
import { createSlice } from '@reduxjs/toolkit';

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: {
    files: [],
    isPaid: false,
  },
  reducers: {
    addFiles: (state, action) => {
      state.files = [...state.files, ...action.payload];
    },
    isPaidStatus: (state, action) => {
      state.isPaid = action.payload;
    },
    removeFile: (state, action) => {
      state.files = state.files.filter((_, index) => index !== action.payload);
    },
    resetFiles: (state) => {
      state.files = [];
    },
  },
});

export const { addFiles, isPaidStatus, removeFile, resetFiles } = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
