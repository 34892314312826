import React, { useState } from 'react';
import Loader from './loader/loader';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from './utils/utils';
import axios from 'axios';
import { toast } from 'react-toastify';

function UserForm() {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseURL}/add/user`, { username, password });
      toast.success('User Added successful!');

      // Clear input fields
      setUserName('');
      setPassword('');


    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Signup failed. Please try again.');
    }
  };

  return (
    <>
      <Loader loading={loading} text={loadingText} />
      <Navbar />
      <div>
        <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
      </div>
      <div className='flex flex-col mt-10 items-center h-screen space-y-4'>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 w-96 text-sm font-bold mb-2" htmlFor="email">
              Enter Email Address
            </label>
            <input
              type="email"
              id="username"
              placeholder='username'
              name="username"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Enter Password
            </label>
            <input
              type="password"
              id="password"
              name='password'
              placeholder='password'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm-password">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div> */}
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-green-500 hover:bg-[#7CAB41] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add New User
            </button>
          </div>

        </form>
      </div>
    </>

  );
}

export default UserForm;
