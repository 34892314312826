// import React, { useEffect, useState } from 'react'
// import Navbar from './Navbar'
// import { useNavigate } from 'react-router-dom'
// import Loader from './loader/loader';
// import { useSelector } from 'react-redux';
// import { baseURL } from './utils/utils';
// import axios from 'axios';

// const UsageReport = () => {
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false);
//     const [loadingText, setLoadingText] = useState('');
//     const { user, userType } = useSelector(state => state?.auth);
//     const isuserAdmin = (user === 'admin@claimguard.com');

//     const [apiUsage, setApiUsage] = useState([]);
//     console.log("🚀 ~ Dashboard ~ apiUsage:", apiUsage)

//     useEffect(() => {
//         const fetchApiUsage = async () => {
//             try {
//                 const response = await axios.get(`${baseURL}/admin/api_usage`);
//                 if(Array.isArray(response.data)){
//                     setApiUsage(response.data);
//                 }
               
//                 console.log("🚀 ~ fetchApiUsage ~ response:", response)
//             } catch (error) {
//                 console.error('Error fetching API usage:', error);
//             }
//         };

//         //   if(isuserAdmin){
//         fetchApiUsage();
//         //   }
//     }, []);


//     return (
//         <>
//             <Loader loading={loading} text={loadingText} />
//             <Navbar />
//             <div>
//                 <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
//             </div>
         
//             <div className='flex flex-wrap justify-between'>
//                 {apiUsage.length > 0 ? apiUsage?.map(api => (
//                     <div key={api?.id} className='p-5 flex flex-col w-1/2 md:w-1/3'> {/* Adjust width here */}
//                         <div className='flex flex-col gap-5 px-5 py-5 border-black border'>
//                             <span
//                                 className="bg-blue-500 text-white font-semibold text-center py-3 px-4 text-lg rounded shadow hover:bg-green-600 transition duration-200 user-select-text"
//                             >
//                                 {api?.username}
//                             </span>

//                             <div className='flex flex-row gap-5 justify-center'>
//                                 <div className='flex flex-col items-center justify-center border border-gray-400'>
//                                     <div className='border border-grap-500 bg-[#5082BF] w-full  text-white text-xs py-2 '><span className='px-2 '>Add-On / Check</span></div>
//                                     <div className='py-5  font-bold overflow-auto '> <span >{api?.validate_medications_count ?? 0}</span></div>
//                                 </div>
//                                 <div className='flex flex-col items-center justify-center border border-gray-400'>
//                                     <div className='border border-grap-500 bg-[#5082BF] w-full  text-white text-xs py-2 '><span className='px-2 '>Reports Generation / Invoice</span></div>
//                                     <div className='py-5  font-bold overflow-auto '> <span >{api?.upload_count ?? 0}</span></div>
//                                 </div>

//                                 <div className='flex flex-col items-center justify-center border border-gray-400'>
//                                     <div className='border border-grap-500 bg-[#5082BF] w-full  text-white text-xs py-2 '><span className='px-2 '>Pdf Upload / File</span></div>
//                                     <div className='py-5  font-bold overflow-auto '> <span >{api?.pdf_upload_count ?? 0}</span></div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 ))
//                 :
//                 <div>
//                 <h2 className='p-5 font-bold text-xl text-blue-800'>No Data Found</h2>
//                 </div>
//                 }
//             </div>
//         </>
//     )
// }

// export default UsageReport

import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import Loader from './loader/loader';
import { useSelector } from 'react-redux';
import { baseURL } from './utils/utils';
import axios from 'axios';

const UsageReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading API usage data...');
    const [apiUsage, setApiUsage] = useState([]);
    const [error, setError] = useState('');
    const { user } = useSelector(state => state?.auth);
    const isUserAdmin = (user === 'admin@claimguard.com');

    // State for date inputs
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const fetchApiUsage = async () => {
            setLoading(true);
            setError(''); // Reset error message
            try {
                const response = await axios.get(`${baseURL}/admin/api_usage`);
                if (Array.isArray(response.data)) {
                    setApiUsage(response.data);
                }
            } catch (error) {
                console.error('Error fetching API usage:', error);
                setError('Failed to fetch API usage data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (isUserAdmin) {
            fetchApiUsage();
        }
    }, [isUserAdmin]);

    // New function to fetch API usage by date range
    const fetchApiUsageByDateRange = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true);
        setError(''); // Reset error message
        try {
            const response = await axios.get(`${baseURL}/admin/api_usage/range`, {
                params: {
                    start_date: startDate,
                    end_date: endDate,
                },
            });
            if (Array.isArray(response.data)) {
                setApiUsage(response.data);
            }
        } catch (error) {
            console.error('Error fetching API usage by date range:', error);
            setError('Failed to fetch API usage data for the specified date range. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateReport = async (userName) => {
        setLoading(true);

        try {
            const response = await axios.get(`${baseURL}/generate-usage-report/${userName}`, {
                params: {
                    // username: 'username_here', // Replace with actual username
                    start_date: startDate,
                    end_date: endDate,
                },
                responseType: 'blob', // Important for handling file downloads
            });

            // Create a link element, use it to download the file, and then remove it
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${userName}_usage_report.pdf`); // Specify the file name here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("There was an error generating the report:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Loader loading={loading} text={loadingText} />
            <Navbar />
            <div className='flex flex-row gap-5 justify-start items-center my-3'>
                <button className='px-8 py-2 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500' onClick={() => navigate(-1)}>Go Back</button> <h2 className='p-3 font-bold text-xl text-blue-800'>Usage Reports</h2>
            </div>
           

            {/* Date Range Form */}
            <form onSubmit={fetchApiUsageByDateRange} className="flex flex-row py-2 justify-center items-center gap-5 border border-gray-300">
                <label>
                    Start Date:
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                        className="border border-gray-300 rounded p-2 ml-2"
                    />
                </label>
                <label>
                    End Date:
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                        className="border border-gray-300 rounded p-2 ml-2"
                    />
                </label>
                <button type="submit" className=" px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    Get Data
                </button>
            </form>

          { !error ?  <div className='flex flex-wrap justify-between'>
                {/* {error && <div className='p-5 text-red-600'>{error}</div>} */}
                {apiUsage.length > 0 ? apiUsage.map(api => (
                    <div key={api.user_id} className='p-5 flex flex-col w-1/2 md:w-1/3'>
                        <div className='flex flex-col gap-5 px-5 py-5 border-black border'>
                            <span className="bg-blue-500 text-white font-semibold text-center py-3 px-4 text-lg rounded shadow hover:bg-green-600 transition duration-200 select-none" onClick={() => handleGenerateReport(api?.username)}>
                                {api?.username}
                            </span>
                            <div className='flex flex-row gap-5 justify-center'>
                                <div className='flex flex-col items-center justify-center border border-gray-400'>
                                    <div className='border border-grap-500 bg-[#5082BF] w-full text-white text-xs py-2'>
                                        <span className='px-2'>Add-On / Check</span>
                                    </div>
                                    <div className='py-5 font-bold overflow-auto'>
                                        <span>{api.validate_medications_count ?? 0}</span>
                                    </div>
                                </div>
                                <div className='flex flex-col items-center justify-center border border-gray-400'>
                                    <div className='border border-grap-500 bg-[#5082BF] w-full text-white text-xs py-2'>
                                        <span className='px-2'>Reports Generation / Invoice</span>
                                    </div>
                                    <div className='py-5 font-bold overflow-auto'>
                                        <span>{api.upload_count ?? 0}</span>
                                    </div>
                                </div>
                                <div className='flex flex-col items-center justify-center border border-gray-400'>
                                    <div className='border border-grap-500 bg-[#5082BF] w-full text-white text-xs py-2'>
                                        <span className='px-2'>Pdf Upload / File</span>
                                    </div>
                                    <div className='py-5 font-bold overflow-auto'>
                                        <span>{api.pdf_upload_count ?? 0}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className='text-center'>
                        <h2 className='p-5 font-bold text-xl text-blue-800'>No Data Found</h2>
                    </div>
                )}
            </div> :
             <div className='text-center'>
            <h2 className='p-5 font-bold text-xl text-blue-800'>  No Record Found for specified dates</h2>
          </div>
            }
        </>
    );
}

export default UsageReport;
