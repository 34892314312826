import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import formDataReducer from './formDataSlice'; // Update with your actual path
import authSlice from './authSlice ';
import fileUploadSlice from './fileUploadSlice';


const store = configureStore({
  reducer: {
    auth: authSlice,
    formData: formDataReducer,
    fileUpload: fileUploadSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
