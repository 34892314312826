import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { toast } from 'react-toastify';

const ContactUs = () => {
  const [state, handleSubmit] = useForm("meojplyq");  //real User Data
  // const [state, handleSubmit] = useForm("mrbzoyga"); //Testing data
  if (state.succeeded) {
    return <p className='text-center font-bold text-4xl text-blue-800 my-10'>Thank you Contacting Us. We will get back to you shortly!</p>;
  }

  return (
    <div className=' mt-20 mb-5 '>
      <form className='flex flex-col gap-5 text-center justify-center' onSubmit={handleSubmit}>
        <div className='text-4xl font-bold text-[#5E5E5E]'>Contact US</div>
        <div className='text-sm font-bold'>We will call you within 24 hours</div>
        <div className='flex flex-col gap-5 px-72'>
          <input placeholder='Name*' type='text' name='name' className='py-5 px-10 border-[#5E5E5E] border' required />

          <input placeholder='Email*' type='email' name='email' className='py-5 px-10 border-[#5E5E5E] border' required />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />

          <input placeholder='Phone' type='text' className='py-5 px-10 border-[#5E5E5E] border' required name='phone' />

          <textarea placeholder='Other Notes' name='message' className='py-5 px-10 border-[#5E5E5E] border' style={{ height: '200px' }} />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>
        <div className='text-center mt-5'>
          <button type="submit" disabled={state.submitting} className='p-3 bg-[#d1caca] text-black font-bold rounded-full w-36 '>Submit</button>
        </div>
      </form>
      <div className='text-center text-xs mt-10 font-semibold'>
        This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
      </div>

      <div className='text-center text-xl font-bold text-black mt-5 py-5'>
        Claim Guard KSA
      </div>

    </div>
  )
}

export default ContactUs