import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Navbar from './Navbar';
import { baseURL } from './utils/utils';
import { useNavigate } from 'react-router-dom';

const UploadReport = () => {
    const [users, setUsers] = useState([]); // State to hold the list of users
    const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user
    const [file, setFile] = useState(null); // State to hold the selected file
    const [loading, setLoading] = useState(true); // State to manage loading state
    const navigate = useNavigate()

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${baseURL}/users`);
                setUsers(response.data.users); // Store the users in state
            } catch (error) {
                const errorMessage = error.response?.data?.error || 'Failed to fetch users';
                toast.error(errorMessage);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchUsers(); // Call the fetch function
    }, []); // Empty dependency array means this effect runs once on mount

    const handleUserClick = (user) => {
        setSelectedUser(user); // Set the selected user
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Set the selected file
    };

    const handleUpload = async () => {
        if (!file) {
            toast.error('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('username', selectedUser);

        try {
            const response = await axios.post(`${baseURL}/uploadreport/` + selectedUser, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success(response.data.message); // Show success message
            setFile(null); // Reset file input
            setSelectedUser(null); // Reset selected user
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to upload report';
            toast.error(errorMessage);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show loading state
    }

    return (
        <div>
            <Navbar />
            <div>
                <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
            </div>
            <div className='p-10 font-bold text-blue-800'>
                <div>Please Click Any Username To Download Thier Uploaded Documents:</div>
                <div className="grid grid-cols-5 gap-4 mt-5">
                    {users.map((user, index) => (
                        <button
                            key={index}
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200"
                            onClick={() => handleUserClick(user)} // Call handleUserClick on button click
                        >
                            {user}
                        </button>
                    ))}
                </div>


                {selectedUser && (
                    <div className="flex flex-row justify-center items-center bg-blend-overlay mix-blend-overlay gap-5 mt-5">
                        <h2>Upload Report for <span className='font-bold text-blue-800'>{selectedUser}</span></h2>
                        <input type="file" onChange={handleFileChange} />
                        <button
                            className="bg-green-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200 mt-2"
                            onClick={handleUpload} // Call handleUpload on button click
                        >
                            Upload Report
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadReport;
