import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user,userType } = useSelector(state => state.auth);
  console.log("🚀 ~ ProtectedRoute ~ userType:", userType)
  


  // Check if the current user type is allowed to access the route
  if (!allowedRoles.includes(userType) && user!=="admin@claimguard.com") {
    // Redirect to home or any other page if the user type is not allowed
    return <Navigate to="/dashboard" replace />;
  }

  // Render the children components (the route's component) if allowed
  return children;
};

export default ProtectedRoute;
