import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Navbar from './Navbar';
import Loader from './loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from './modal/ModalComponent';
import { baseURL } from './utils/utils';

const UploadPdf = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState('');
    const { user, userType } = useSelector(state => state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const isuserAdmin = (user === 'admin@claimguard.com');
    const isAdminUser = (userType === 'admin_user');


    useEffect(() => {
        const paymentSuccess = location.pathname.includes('success');
        const paymentFailure = location.pathname.includes('failure') || location.pathname.includes('cancelled');

        if (paymentSuccess) {
            !isAdminUser && !isuserAdmin && toast.success('Files Uploaded Successfully.');
            handlePaymentConfirmation(true)

        } else if (paymentFailure) {
            toast.error('Error in Transaction. Try Again');
        } else {
            toast.info('Please upload pdf files');
        }
    }, [location.pathname]);

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
    };


    const handleUploadSubmit = async (e) => {
        e.preventDefault();

        if (files.length === 0) {
            toast.error("Please select files to upload.");
            return;
        }

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        formData.append('username', user); // Append the username to the form data
        try {
            setLoading(true);
            const response = await fetch(`${baseURL}/uploadpdf`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (response.ok) {
                toast.success('Files uploaded successfully');
                setIsModalOpen(true);
                setApiResponse(result);
                setFiles([]); // Clear the files after upload
            } else {
                toast.error(result?.message ?? 'File upload error');
            }
        } catch (error) {
            toast.error("An error occurred while uploading the files.");
        } finally {
            setLoading(false);
        }
    };

    const handlePaymentConfirmation = async (isConfirmed) => {
        try {
            const response = await fetch(`${baseURL}/paymentconfirmed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: user, confirmed: isConfirmed }),
            });

            const result = await response.json();

            if (response.ok) {
                !isAdminUser && !isuserAdmin && toast.success(result.message);
                setIsModalOpen(false); // Close the modal after confirmation
                navigate('/uploadpdf');
            } else {
                toast.error(result?.message ?? 'Payment confirmation failed');
                navigate('/uploadpdf');
            }
        } catch (error) {
            // toast.error("An error occurred while confirming the payment.");
            navigate('/uploadpdf'); // Clear the files after upload

        }
    };

    return (
        <>
            <Navbar />
            <Loader loading={loading} text='Uploading' />
            <div>
                <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
            </div>
            <ModalComponent
                isOpen={isModalOpen}
                isUploadPdf={true}
                onRequestClose={() => setIsModalOpen(false)}
                apiResponse={apiResponse}
            // Cancel payment
            />
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <form onSubmit={handleUploadSubmit}>
                    <div className="bg-gray-200 flex flex-col gap-4 p-5 rounded-lg justify-center items-center">
                        <div className="flex flex-col gap-2 w-full">
                            <input
                                type="file"
                                accept="application/pdf"
                                multiple
                                onChange={handleFileChange}
                                className="cursor-pointer"
                            />
                            <div>
                                {files.length > 0 && (
                                    <ul className="list-disc list-inside">
                                        {files.map((file, index) => (
                                            <li key={index} className="text-gray-700">
                                                {file.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200"
                        >
                            Upload PDF Files
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default UploadPdf;
