import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from './utils/utils';
import { toast } from 'react-toastify';
import Loader from './loader/loader';
import Navbar from './Navbar';
import { FaRegTrashCan } from 'react-icons/fa6';
import { SiAdblock } from 'react-icons/si';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState('');

    useEffect(() => {
        getUserData();
    }, []);
    const handleBlock = async (userId) => {

        try {
            const token = localStorage.getItem('token');
            await axios.patch(`${baseURL}/admin/users/${userId}/block`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            toast.success('User Blocked successfully!');
            getUserData();
        } catch (error) {
            toast.error('Failed to Block user');
        }
    };

    const handleUnBlock = async (userId) => {

        try {
            const token = localStorage.getItem('token');
            await axios.patch(`${baseURL}/admin/users/${userId}/unblock`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            toast.success('User Un-Blocked successfully!');
            getUserData();
        } catch (error) {
            toast.error('Failed to Un-Block user');
        }
    };

    const handleDelete = async (userId) => {

        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${baseURL}/admin/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            getUserData();
        } catch (error) {
            alert('Failed to delete user');
        }
    };

    const confirmDelete = (userId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(userId);
                Swal.fire(
                    'Deleted!',
                    'User has been deleted.',
                    'success'
                );
            }
        });
    };

    const getUserData = () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setLoadingText('Fetching users...');

        axios.get(`${baseURL}/admin/users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setLoading(false);
                setUsers(response?.data?.admin_users);
            }
            )
            .catch(error => {
                setLoading(false);
                toast.error(error);
            });
    }




    return (
        <>
            <Loader loading={loading} text={loadingText} />
            <Navbar />
            <div>
                <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
            </div>
            <h2 className='p-5 font-bold text-xl text-blue-800'>Users List</h2>

            {/* {users?.map(user => (
                <div key={user?.id} className='p-5 flex flex-row '>
                    <div className='flex flex-col gap-5 px-5 py-5 border-black border '>
                        <span
                            className="bg-blue-500 text-white  font-semibold  text-center py-3 px-4 text-lg rounded shadow hover:bg-green-600 transition duration-200 user-select-text"
                        >
                            {user?.username}
                        </span>

                        <div className='flex flex-row justify-between gap-5 '>

                            <button onClick={() => confirmDelete(user?.id)}
                                className="bg-red-500 text-white font-semibold   text-center py-2 px-4 rounded shadow hover:bg-red-600 transition duration-200 flex flex-row gap-2 items-center"
                            >
                                <FaRegTrashCan />  Delete User
                            </button>

                            <button onClick={() => { user?.blocked ? handleUnBlock(user?.id) : handleBlock(user?.id) }}
                                className={`${user?.blocked ? 'bg-gray-500' : 'bg-green-500'} text-white font-semibold  text-center py-2 px-4 rounded shadow ${user?.blocked ? 'hover:bg-gray-600' : 'hover:bg-green-600'} transition duration-200 flex flex-row items-center gap-2`}
                            >
                                <SiAdblock /> Block/Un-Block ({user?.blocked ? 'Blocked' : 'Active'})
                            </button>

                        </div>
                    </div>


                </div>
            ))} */}
            <div className='flex flex-wrap justify-between'>
                {users?.map(user => (
                    <div key={user?.id} className='p-5 flex flex-col w-1/2 md:w-1/3'> {/* Adjust width here */}
                        <div className='flex flex-col gap-5 px-5 py-5 border-black border'>
                            <span
                                className="bg-blue-500 text-white font-semibold text-center py-3 px-4 text-lg rounded shadow hover:bg-green-600 transition duration-200 user-select-text"
                            >
                                {user?.username}
                            </span>

                            <div className='flex flex-row justify-between gap-5'>
                                <button onClick={() => confirmDelete(user?.id)}
                                    className="bg-red-500 text-white font-semibold text-center py-2 px-4 rounded shadow hover:bg-red-600 transition duration-200 flex flex-row gap-2 items-center"
                                >
                                    <FaRegTrashCan /> Delete User
                                </button>

                                <button onClick={() => { user?.blocked ? handleUnBlock(user?.id) : handleBlock(user?.id) }}
                                    className={`${user?.blocked ? 'bg-gray-500' : 'bg-green-500'} text-white font-semibold text-center py-2 px-4 rounded shadow ${user?.blocked ? 'hover:bg-gray-600' : 'hover:bg-green-600'} transition duration-200 flex flex-row items-center gap-2`}
                                >
                                    <SiAdblock /> Block/Un-Block ({user?.blocked ? 'Blocked' : 'Active'})
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>





    );
};

export default UsersList;
