import React, { useState } from 'react';
import Modal from 'react-modal';
import PaymentComponent from '../PaymentComponent';
import Loader from '../loader/loader';
import { useDispatch, useSelector } from 'react-redux';

const ModalComponent = ({ isOpen, onRequestClose, apiResponse, onPaymentSuccess, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const { user, userType } = useSelector(state => state.auth);
  const isuserAdmin = (user === 'admin@claimguard.com');
  const isAdminUser = (userType === 'admin_user');


  const handlePaymentSuccess = (payment) => {
    dispatch({ type: 'isPaidStatus', payload: true });
    onPaymentSuccess(payment);
  };



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="flex items-center justify-center h-full"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <Loader loading={loading} text={loadingText} />
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
        {!isAdminUser && !isuserAdmin ?
          <>
            <h2 className="text-2xl font-semibold mb-4 text-center text-blue-900">Service Fee Charge</h2>
            <p className="text-gray-700 text-center mb-6">
              Please Pay <span className='font-bold text-blue-800'>{apiResponse?.total_price} SAR</span> To Continue Processing
            </p>
          </>
          :
          <>Files have been uploaded</>
        }
        <PaymentComponent
          amount={apiResponse?.total_price} onPaymentSuccess={handlePaymentSuccess} isUploadPdf={props?.isUploadPdf}
        />

        <div className="flex justify-center gap-3 items-center mt-4">
          <button
            onClick={onRequestClose}
            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300"
          >
            Close
          </button>

        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
