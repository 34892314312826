// import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoutSuccess } from './redux/authSlice ';
import { toast } from 'react-toastify';
import logo from '../assets/claim-guad-logo.jpeg';
import axios from 'axios';
import Loader from './loader/loader';
import { useState,useEffect } from 'react';
import { baseURL } from './utils/utils';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [reportsExist, setReportsExist] = useState(false); // State to track if reports exist
  const isAdmin = (user === 'admin@claimguard.com');

  const handleLogout = () => {
    dispatch(logoutSuccess());
    toast.success('Logged out successfully!');
    navigate('/login');
  };

  const checkReports = async () => {
    try {
      const response = await axios.get(`${baseURL}/check_reports/${user}`);
      return response.data.exists;
    } catch (error) {
      toast.error('Error checking reports: ' + (error.response?.data?.error || 'Unknown error'));
      return false;
    }
  };

  const downloadReports = async () => {
    const exists = await checkReports();
    setLoading(true);
    if (!exists) {
      toast.error('Reports folder does not exist for this user.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${baseURL}/download_reports/${user}`, {
        responseType: 'blob', // Important for downloading files
      });

      // Create a URL for the file and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${user}_reports.zip`); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success('Reports downloaded successfully!');
      setLoading(false);

    } catch (error) {
      toast.error('Error downloading reports: ' + (error.response?.data?.error || 'Unknown error'));
      setLoading(false);

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchReportsExistence = async () => {
      const exists = await checkReports();
      setReportsExist(exists); // Update state based on existence of reports
      setLoading(false);
    };

    fetchReportsExistence(); // Call the function on component mount
  }, [user]); // Dependency on user to re-check if user changes

  return (
    <nav className="bg-[#0C217A] p-4 shadow-md">
      <Loader loading={loading} />
      <div className="container mx-auto flex justify-between items-center text-white text-lg font-bold">
        <div className="flex items-center gap-4">
          <Link to="/dashboard">
            <img src={logo} alt='logo' width={100} className='rounded-full' />
          </Link>
          <span className="text-xl">{user}</span>
        </div>

        <div className="flex items-center gap-6">
          {isAdmin && (
            <>
            
              <Link to="/downloadpdf" className="bg-red-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-red-600 transition duration-200">
                Review Documents
              </Link>
              <Link to="/uploadreport" className="bg-green-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200">
                Upload Report
                </Link>
              <Link to="/user-management" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200">
                Manage Users
              </Link>
              <Link to="/usage-report" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200">
               Usage Reports
              </Link>
            </>
          )}
          {!isAdmin && reportsExist && ( // Show button only if reports exist
            <button onClick={downloadReports} className="bg-green-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-600 transition duration-200">
              Download Claim Report
            </button>
          )}
          <button onClick={handleLogout} className="text-white hover:underline transition duration-200">
            Log Out
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
