// src/ClaimsForm.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData, setDataDict, addRow, removeRow, resetForm } from './redux/formDataSlice.js';
import Navbar from './Navbar.jsx';
import { toast } from 'react-toastify';
import Loader from './loader/loader.js';
import ModalComponent from './modal/ModalComponent.js';
import { baseURL } from './utils/utils.js';
import axios from 'axios';


const ClaimsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formData = useSelector(state => state.formData?.formData);
  const dataDict = useSelector(state => state.formData?.dataDict);
  const { user, userType } = useSelector(state => state.auth);
  const isuserAdmin = (user === "admin@claimguard.com")
  const isAdminUser = (userType === "admin_user")
  const isDataDictEmpty = Object.values(dataDict).every(arr => arr.length === 0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState('');


  useEffect(() => {
    dispatch(resetForm());
  }, [])

  const initialFormState = {
    'INV_NO': '', 'FILE_NO/MRN_NO': '', 'ID_NO/IQAMA_NO': '', 'MBR_NAME': '', 'MBR_NO': '',
    'GENDER': '', 'INV_DATE': '', 'INCUR_DATE_FROM': '', 'ICD10_1': '', 'ICD DESCRIPTION_1': '',
    'ICD10_2': '', 'ICD DESCRIPTION_2': '', 'ICD10_3': '', 'ICD DESCRIPTION_3': '', 'ICD10_4': '',
    'ICD DESCRIPTION_4': '', 'CHIEF_COMPLAIN': '', 'TEMPERATURE': '', 'BLOOD_PRESSURE': '',
    'PULSE': '', 'DURATION_OF_ILLNESS': '', 'TOOTH_NO': '', 'PREAUTHRIZATION_NO/APPROVAL_NO': '',
    'SERV_TYPE': '', 'SPECIALITY': '', 'NPHIES_CODE': '', 'SERV_CODE': '', 'SERV_DESC': '',
    'QTY': '', 'PRESENT_AMT': ''
  };

  const handlePaymentSuccess = async (paymentStatus) => {
    try {
      const response = await axios.post(`${baseURL}/payment-success/${user}`, {
        // username: user,
        payment_successful: paymentStatus
      });

      // Handle success response
      // toast.success(response.data.message);
    } catch (error) {
      // Handle error response
      if (error.response) {
        // toast.error("Payment Error no new report will be genrated.");
      } else {
        toast.error('An error occurred while processing your request.');
      }
    }
  };


  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newFormData = formData.map((item, idx) =>
      idx === index ? { ...item, [name]: value } : item
    );
    dispatch(setFormData(newFormData));
  };



  const handleAddRow = () => {
    dispatch(addRow());
  };

  const handleRemoveRow = (index) => {
    dispatch(removeRow(index));
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (isDataDictEmpty) {
      // alert('Cannot submit. Data dictionary is empty.');
      setLoading(false);
      return toast.error('Cannot submit empty data rows.');
      // return;
    }

    try {
      await submitData(dataDict); // Replace with your API call
      // dispatch(resetForm()); // Reset form after successful submission
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const submitData = async (dataDict) => {


    try {
      const response = await fetch(`${baseURL}/submit/${user}`, {  // Update with your actual API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataDict),
      });

      const result = await response.json();
      if (response.ok) {
        setLoading(false);
        toast.success('File uploaded successfully');
        if (isuserAdmin || isAdminUser) {
          handlePaymentSuccess(true);
          navigate("/dashboard");
        }
        else {
          setIsModalOpen(true);
          setApiResponse(result);
        }



      } else {
        toast.error(result?.error ?? 'File upload failed');
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };


  return (
    <>
      <Loader loading={loading} text='Uploading' />
      <Navbar />
      {!isuserAdmin && !isAdminUser && <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        apiResponse={apiResponse}
      />}
      <div className="mx-auto px-20 py-5">
        <div>
          <button className='px-8 py-2 mt-5 mb-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
        </div>
        <span className='flex gap-2 align-center items-center mb-2'><h1 className="text-xl font-bold p-1">Please Add Data in Each Field Manually  </h1> <span className='text-sm text-white font-bold bg-green-500 p-1 rounded-lg'>Please Click Add Row Button After Adding Data</span></span>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="overflow-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  {Object.keys(initialFormState).map((field, index) => (
                    <th key={index} className="px-4 py-2 border">{field.replace(/_/g, ' ')}</th>
                  ))}
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.map((data, rowIndex) => (
                  <tr key={rowIndex} className=''>
                    {Object.keys(initialFormState).map((field, colIndex) => (
                      <td key={colIndex} className="border px-4 py-2 ">
                        <input
                          type="text"
                          name={field}
                          value={data[field] || ''}
                          onChange={(e) => handleChange(rowIndex, e)}
                          className="p-2 border border-gray-300 rounded-md w-[200px]"
                        />
                      </td>
                    ))}
                    <td className="border px-4 py-2">
                      <button
                        type="button"
                        onClick={() => handleRemoveRow(rowIndex)}
                        className="bg-red-500 text-white py-1 px-3 rounded-md hover:bg-red-700"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={handleAddRow}
              className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-700 mr-2"
            >
              Add Row
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ClaimsForm;
