import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PaymentComponent = ({ amount = 1000, onPaymentSuccess,...props }) => {
  const [paymentId, setPaymentId] = useState(null);
  const navigate = useNavigate();
  const { user, userType } = useSelector(state => state.auth);
  const isuserAdmin = (user === 'admin@claimguard.com');
  const isAdminUser = (userType === 'admin_user');

  const isUploadPdf = props?.isUploadPdf || false;

    useEffect(() => {
        
           isAdminUser || isuserAdmin ? ( isUploadPdf ? navigate("/uploadpdf-success") : navigate("/dashboard-success")): initializeHostedPayment() 
        
    }, []); 

    const initializeHostedPayment = async () => {
        try {
            const response = await axios.post(
                'https://api.sandbox.checkout.com/hosted-payments',//sandbox testing need live secret key
                // 'https://api.checkout.com/hosted-payments',Live API
                {
                    amount: amount * 100, // Amount in the smallest currency unit (e.g., pence for GBP)
                    currency: "SAR",
                    reference: "ORD-123A",
                    billing: {
                        address: {
                            country: "AR"
                        }
                    },
                    success_url: isUploadPdf ? "https://claimguardksa.com/uploadpdf-success" : "https://claimguardksa.com/dashboard-success",
                    failure_url: isUploadPdf ? "https://claimguardksa.com/uploadpdf-failure" : "https://claimguardksa.com/dashboard-failure",
                    cancel_url:  isUploadPdf ? "https://claimguardksa.com/uploadpdf-cancelled" : "https://claimguardksa.com/dashboard-cancelled",
                    //success_url: isUploadPdf ? "http://localhost:3000/uploadpdf-success" : "http://localhost:3000/dashboard-success",
                    //failure_url: isUploadPdf ? "http://localhost:3000/uploadpdf-failure" : "http://localhost:3000/dashboard-failure",
                    //cancel_url:  isUploadPdf ? "http://localhost:3000/uploadpdf-cancelled" : "http://localhost:3000/dashboard-cancelled",
                    processing_channel_id: "pc_4ovoqkmwxpyedebgjmmyysfcqe"
                },
                {
                    headers: {
                        Authorization: 'Bearer sk_sbox_q6uqghdkif54avoslc4dgfk7aul', // Your secret API key
                        'Content-Type': 'application/json'
                    },

                }
            );

            // Set the payment ID and redirect the user to the payment page
            if (response.data && response.data.id) {
                setPaymentId(response.data.id);
                window.location.href = response.data._links.redirect.href;
            } else {
                toast.error('No redirect link found in the response');
            }
        } catch (error) {
            toast.error('Error initializing hosted payment:', error.response ? error.response.data : error.message);
        }
    };

  

    return (
        <section className="flex h-full w-full justify-center items-center">
            <div style={{ width: '360px', margin: '40px 0 0 0' }}>
              { !isAdminUser && !isuserAdmin &&   <p>Redirecting to payment...</p>}
            </div>
        </section>
    );
};

export default PaymentComponent;
