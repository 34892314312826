import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { loginSuccess } from '../components/redux/authSlice ';
import logo_img from "../assets/claim-guad-logo.jpeg";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from './utils/utils';

const Login = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  
const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/login`, { username, password });
      const { user, token,user_type } = response.data;

      dispatch(loginSuccess({ user, token,user_type }));
      toast.success('Login successful!');
      
      // Clear input fields
      setUserName('');
      setPassword('');

      // Redirect to the dashboard or another route
      navigate('/dashboard');
    } catch (error) {
      toast.error('Login failed. Please check your credentials.');
      toast.error(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center ">
      {/* <ToastContainer/> */}
      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden border-[#0C217A] border">
        <div className="md:w-1/2 p-8 bg-[#0C217A] flex flex-col justify-center">
          <div className="flex justify-center mb-8 ">
            <img src={logo_img} alt="Claim Guard Logo" className="h-32  rounded-xl" />
          </div>
          <h2 className="text-2xl font-bold text-center mb-4 text-[#7CAB41] ">Welcome to Claim Guard KSA</h2>
          <p className="text-center text-white mb-8 font-bold">Avoid Rejections, Increase Revenues</p>
        </div>
        <div className="md:w-1/2 p-8">
          <h2 className="text-2xl font-bold text-center mb-6">Login To Your Account</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Enter username Address
              </label>
              <input
                type="username"
                id="username"
                placeholder='username'
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => setUserName(e.target.value)}
                value={username}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Enter Password
              </label>
              <input
                type="password"
                id="password"
                placeholder='Password'
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-[#7CAB41] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Login
              </button>
            </div>
            <div className="mt-6 text-center">
              <p>
                Don't have an account?
                <Link
                  to="/signup"
                  className="text-blue-500 hover:text-[#7CAB41] font-bold"
                >
                  Signup
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

