import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = ({ children }) => {
  const { user } = useSelector(state => state.auth);
  


  // Check if the current user type is allowed to access the route
  if ( user!=="admin@claimguard.com") {
    // Redirect to home or any other page if the user type is not allowed
    return <Navigate to="/dashboard" replace />;
  }

  // Render the children components (the route's component) if allowed
  return children;
};

export default AdminRoute;
