import React from 'react'
import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'

const UserManagement = () => {
    const navigate = useNavigate()
    return (
        <>
            <Navbar />
            <div>
        <button className='px-8 py-2 mt-5 ml-5 border border-gray-500 rounded-lg hover:bg-blue-500 ' onClick={() => navigate(-1)}>Go Back</button>
      </div>
            <div className='flex flex-row gap-10 p-10 justify-center'>

                <Link to="/add-users" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200">
                    Add New Internal User
                </Link>
                <Link to="/user-list" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-blue-600 transition duration-200">
                    Manage Users
                </Link>

            </div>
        </>
    )
}

export default UserManagement